import React from 'react';
import './App.css';

function App() {
  return (
    <div className="main">
      <p>Hi, I'm David. I'm a software engineer in SF.</p>
      <p>You can find me on <a href="https://www.linkedin.com/in/da-vid/">LinkedIn</a> or reach me by <a href="mailto:me@davidl.ee">email</a>.</p>
    </div>
  );
}

export default App;
